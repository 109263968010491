import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class AdditionalHeadersInterceptor implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.append('X-APP-NAME', 'web-app'),
    });

    return next.handle(request).pipe(
      catchError((e) => {
        if (e.status === 0) {
          this.snackBar.open('Connection error. Check if you have access to internet, then try again.');

          return EMPTY;
        }

        return throwError(e);
      })
    );
  }
}
