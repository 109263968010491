<div id="page" #page>

  <!--<header>-->

  <!--<mat-toolbar color="primary">-->
  <!--<mat-toolbar-row>-->
  <!--</mat-toolbar-row>-->

  <!--<mat-toolbar-row>-->
  <!--<span style="font-size: 14px; font-weight: 400;">Manage doors</span>-->
  <!--</mat-toolbar-row>-->
  <!--</mat-toolbar>-->
  <!--</header>-->

  <div id="header">
    <logout-button></logout-button>

    <img *ngIf="logoUrl" src="{{logoUrl}}" style="max-width: 200px;max-height: 100px;top: 50%;-ms-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);position: absolute;" />
  </div>

  <div class="container">

    <div id="content" style="margin-bottom: 100px">
      <app-doors-list></app-doors-list>
    </div>
  </div>
</div>
