import {NgModule} from '@angular/core';
import {MaterialCommonModule} from '@shared/material-common.module';
import {RouterModule} from '@angular/router';
import {MainPageComponent} from './main-page/main-page.component';
import {AuthGuard} from '@core/security/guards/auth-guard.service';
import {CommonModule} from '@angular/common';
import {DoorComponent} from './components/door/door.component';
import {DoorsListComponent} from './components/doors-list/doors-list.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {AuthByTokenComponent} from './components/auth-by-token/auth-by-token.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialCommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: MainPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'auth-by-token',
        component: AuthByTokenComponent
      },
      {
        path: 'error',
        component: ErrorPageComponent
      }
    ])
  ],
  declarations: [MainPageComponent, DoorsListComponent, DoorComponent, ErrorPageComponent, AuthByTokenComponent]
})
export class PagesModule {
}
