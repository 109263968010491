import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  public get(path: string, queryParams = {}): Observable<any> {
    const params = this.getHttpParamsFromObject(queryParams);

    // todo - extract when/if functionality grows
    const headers = new HttpHeaders({
      Authorization: [`Bearer ${localStorage.getItem('accessToken')}`]
    });

    return this.http.get(`${environment.apiUrl}${path}`, {headers, params}).pipe(
      catchError(this.formatErrors)
    );
  }

  public put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: Object = {}): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: [`Bearer ${localStorage.getItem('accessToken')}`]
    });

    return this.http.post(`${environment.apiUrl}${path}`, body, {headers})
      .pipe(catchError(this.formatErrors));
  }

  public delete(path): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${path}`)
      .pipe(catchError(this.formatErrors));
  }

  public getHttpParamsFromObject(object = {}): HttpParams {

    return new HttpParams({fromObject: object});
  }
}
