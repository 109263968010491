import {Component} from '@angular/core';
import {AuthService} from '@core/security/services/auth.service';
import {take, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'logout-button',
  templateUrl: './logout-button.html'
})
export class LogoutButtonComponent {
  constructor(private authService: AuthService) {
  }

  public logout() {
    if (this.authService.isAuthenticated) {
      this.authService.logout().pipe(
        take(1),
        catchError(err => {
          localStorage.clear();
          window.location.reload();

          return throwError(err);
        })
      ).subscribe(() => {
        window.location.reload();
      });
    } else {
      localStorage.clear();
      window.location.reload();
    }
  }
}
