import {NgModule} from '@angular/core';
import {MatSpinnerButtonComponent} from './components/spinner-button/spinner-button.component';
import {HoldButtonComponent} from '@shared/components/hold-button/hold-button.component';
import {TimerMatProgressBarComponent} from '@shared/components/timer-mat-progress-bar/timer-mat-progress-bar.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {LogoutButtonComponent} from '@shared/components/logout-button/logout-button.component';
import {MobileAppsComponent} from '@shared/components/mobile-apps/mobile-apps.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    MatBottomSheetModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatStepperModule,
    MatDialogModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatCardModule,
    MatChipsModule,
    MatExpansionModule,
    MatToolbarModule,
    MatListModule,
    MatTooltipModule,
    MatSnackBarModule,
    ScrollingModule,
  ],
  exports: [
    MatGridListModule,
    MatBottomSheetModule,
    MatSortModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatStepperModule,
    MatTabsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCardModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatListModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSpinnerButtonComponent,
    MatSnackBarModule,
    HoldButtonComponent,
    LogoutButtonComponent,
    TimerMatProgressBarComponent,
    ScrollingModule,
    MobileAppsComponent,
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000}},
    {provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
  declarations: [MatSpinnerButtonComponent, HoldButtonComponent, TimerMatProgressBarComponent, LogoutButtonComponent, MobileAppsComponent],
  entryComponents: [MobileAppsComponent]
})
export class MaterialCommonModule {

}
