import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@core/security/services/auth.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.html',
  styleUrls: ['./error-page.scss']
})
export class ErrorPageComponent {
  error: { message: string, description: string } = {
    message: '404, Not Found',
    description: 'The link is outdated or an unexpected error happened.'
  };
  location: any;
  logoUrl?: string;
  showInactiveBlock = false;
  showGeneralBlock = true;
  booking: any;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private _bottomSheet: MatBottomSheet) {
    route.params.subscribe((params) => {
      // todo - extract error messages to config file
      if (params && params.type === 'token-not-valid') {
        this.error.message = 'Link is not valid';
      } else if (params && params.type === 'booking-not-active') {
        this.error.message = 'Your booking is no longer active';
        this.error.description = 'Please contact administration for more information.';
      }
    });

    try {
      this.location = JSON.parse(localStorage.getItem('location'));
    } catch (e) {
    }
    if (this.location) {
      this.logoUrl = this.location.logo_url ? this.location.logo_url : null;
    }

    const authToken = localStorage.getItem('authToken');
    const bookingJson = localStorage.getItem('booking');
    const signature = localStorage.getItem('signature');
    const user = localStorage.getItem('user');
    this.booking = JSON.parse(bookingJson);

    if (authToken && bookingJson) {
      this.router.navigateByUrl('/');
    } else {
      if (!authToken && bookingJson && signature) {
        this.authService.authenticateByToken(this.booking.uuid, user, signature)
          .subscribe(() => {
            localStorage.setItem('authToken', this.booking.uuid);
            this.router.navigateByUrl('/');
          });
      }

      if (!authToken && bookingJson) {
        this.showInactiveBlock = true;
      }
    }

    // this._bottomSheet.open(MobileAppsComponent);
  }

  retry() {
    window.location.reload();
  }
}
