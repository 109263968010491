import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'timer-mat-progress-bar',
  template: `
    <div class="progress-bar bar">
      <div #fillBar class="loaded" [ngStyle]="{width: value + '%'}"></div>
      <span id="hold_span">Hold to open</span>
    </div>
  `,
  styleUrls: ['./timer-mat-progress-bar.scss']
})
export class TimerMatProgressBarComponent implements AfterViewInit {
  private _value = 0;

  @ViewChild('fillBar') fillBar: ElementRef;

  @Input() set value(val: number) {
    if (!isNaN(val) && val >= 0 && val <= 100) {
      this._value = val;
    }
  }

  ngAfterViewInit() {
  }

  get value(): number {
    return this._value;
  }
}
