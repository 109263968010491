import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {Observable} from 'rxjs';
import {Door} from '@models/door.model';

@Injectable({providedIn: 'root'})
export class DoorsService {

  constructor(private apiService: ApiService) {
  }

  public getDoors(): Observable<Door[]> {
    const token = localStorage.getItem('authToken');

    return this.apiService.get(`bookings/uuid/${token}/doors`, {
      'include': 'location,is-accessible',
      'limit': 1000,
      'sort': '-is_accessible,title'
    });
  }

  public openDoor(id: string): Observable<any> {
    return this.apiService.post(`doors/${id}/open`);
  }
}
