import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@services/api.service';
import {map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(private apiService: ApiService) {
  }

  private _isAuthenticated = false;

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  set isAuthenticated(value: boolean) {
    this._isAuthenticated = value;
  }

  isUserLoggedIn(): Observable<boolean> {
    return this.getAccessTokenFromStorage().pipe(
      map(token => (!!token))
    );
  }

  // todo - post request by plain HttpClientModule instead of ApiService when/if app complexity grows
  authenticateByToken(token: string, user: string, signature: string = ''): Observable<any> {

    if (!token) {
      throw new TypeError('Auth Token expected but got ' + token);
    }

    return this.sendAuthByTokenRequest(token, user, signature)
      .pipe(
        map(response => response.data),
        tap((data) => {
          this.saveAccessDataInStorage(data);
          this.saveBookingDataInStorage(data);
          this.setUserAuthenticated();
          this.saveSignatureInStorage(signature);

          localStorage.setItem('user', user);
        }, (response: any) => {
          if (response.hasOwnProperty('data')) {
            this.saveBookingDataInStorage(response.data);
            this.saveSignatureInStorage(signature);

            localStorage.setItem('user', user);
          }
        })
      );
  }

  logout(): Observable<any> {
    return this.apiService.post(`auth/logout`).pipe(
      tap(() => {
        localStorage.clear();
      })
    );
  }

  private saveAccessDataInStorage({accessToken, expires_in, applink_url}: IAccessData) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('expires_in', `${expires_in}`);
    localStorage.setItem('applink_url', `${applink_url}`);
  }

  private saveBookingDataInStorage({booking, location, is_active}: IAccessData) {
    localStorage.setItem('booking', JSON.stringify(booking));
    localStorage.setItem('is_active', is_active ? '1' : '0');
    localStorage.setItem('location', JSON.stringify(location));
  }

  private saveSignatureInStorage(signature: string) {
    localStorage.setItem('signature', signature);
  }

  private setUserAuthenticated() {
    this.isAuthenticated = true;
  }

  private sendAuthByTokenRequest(token, user, signature = ''): Observable<any> {
    return this.apiService.post(`auth/auto-login?token=${token}&user=${user}&signature=${signature}`);
  }

  private getAccessTokenFromStorage(): Observable<string> {
    return new Observable(subscriber => {
      const token = localStorage.getItem('accessToken');

      subscriber.next(token);
    });
  }
}

export interface IAccessData {
  accessToken: string;
  expires_in: number;
  booking?: any;
  is_active: boolean;
  location?: any;
  applink_url?: any;
}
