<ng-container *ngIf="doors$ | async as doors; else noDoorsTpl">

  <!--<mat-card *ngIf="!isBookingActive">
    <mat-card-header>
      <mat-card-title>Your reservation is not yet ready</mat-card-title>
      <mat-card-subtitle>Check-in date and time: {{booking.start_at | date:'dd/MM/yyyy HH:mm'}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <button mat-button (click)="retry()">Reload</button>
    </mat-card-actions>
  </mat-card>-->

  <mat-card>
    <mat-card-header>
      <mat-card-title>Welcome to {{location.title}}</mat-card-title>
      <mat-card-subtitle style="margin-bottom: 0">Stay period: {{booking.start_at | date:'MMM d, y HH:mm'}} - {{booking.end_at | date:'MMM d, y HH:mm'}}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-list>
    <mat-divider></mat-divider>
  </mat-list>

  <table *ngIf="doors.length > 0; else noDoorsTpl" mat-table [dataSource]="doors" class="mat-elevation-z3 fullWidth"
         style="margin-top: 8px">
    <ng-container matColumnDef="door">
      <td mat-cell *matCellDef="let door" style="padding-left: 8px; padding-right: 0;">
        <mat-list>
          <app-door [door]="door"></app-door>
        </mat-list>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</ng-container>

<ng-template #noDoorsTpl>
  <mat-card style="margin-top: 8px">
    <div style="text-align: center; width: 100%">No available doors found...</div>
  </mat-card>
</ng-template>
