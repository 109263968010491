import {Component} from '@angular/core';
import {AuthService} from '@core/security/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';

@Component({
  selector: 'app-auth-by-token',
  template: `<h2>Loading...</h2>`
})
export class AuthByTokenComponent {
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService) {
    activatedRoute.params.subscribe(() => {
      if (authService.isAuthenticated) {
        router.navigateByUrl('/').then();
      } else {
        const signature = activatedRoute.snapshot.queryParams['signature'] || '';
        const user = activatedRoute.snapshot.queryParams['user'] || '';
        const token = activatedRoute.snapshot.queryParams['token'] || '';

        localStorage.clear();

        authService.authenticateByToken(token, user, signature)
          .pipe(
            catchError(() => router.navigateByUrl('/error')),
            tap(() => localStorage.setItem('authToken', token))
          )
          .subscribe(() => router.navigateByUrl('/'));
      }
    });
  }
}
