<button mat-button
        [class.active]="options.active"
        [class.mat-raised-button]="options.raised"
        [class.mat-stroked-button]="options.stroked"
        [disabled]="options.disabled"

        [ngStyle]="{'background': !options.disabled ? '#000000' : 'rgba(0,0,0,.12)', 'color': '#ffffff'}"

        (mousedown)="handleMouseDown($event)"
        (mouseup)="handleMouseUp()"
        (click)="handleContextMenuEvent($event)"
        (contextmenu)="handleContextMenuEvent($event)"
        (touchstart)="handleMouseDown($event)"
        (touchend)="handleMouseUp()"
>

  <span class="button-text" [class.active]="options.active">{{ options.text }}</span>

  <ng-container *ngIf="options.progressActive">
    <timer-mat-progress-bar [value]="progressBarValue"></timer-mat-progress-bar>
  </ng-container>
  <mat-spinner
    class="spinner"
    [diameter]="options.spinnerSize"
    [color]="options.spinnerColor"
    [mode]="options.mode"
    [value]="options.value"
    [class.active]="options.spinnerActive"
  ></mat-spinner>
</button>
