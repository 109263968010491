import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-main-page',
  templateUrl: 'main-page.html'
})
export class MainPageComponent implements OnInit {
  @ViewChild('page') pageRef: ElementRef;

  isHeaderCollapsed = false;

  location: any;
  logoUrl?: string;

  constructor(private _bottomSheet: MatBottomSheet) {
    // this._bottomSheet.open(MobileAppsComponent);
  }

  ngOnInit(): void {
    try {
      this.location = JSON.parse(localStorage.getItem('location'));
    } catch (e) {}
    if (this.location) {
      this.logoUrl = this.location.logo_url ? this.location.logo_url : null;
    }
  }
}
