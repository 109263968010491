import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from '@core/security/services/auth.service';

@Injectable()
export class AuthGuard {

  constructor(private router: Router, private authService: AuthService) {
  }

  canLoad(route: Route): Observable<boolean> {
    return this.authService.isUserLoggedIn()
      .pipe(
        tap((isLogged: boolean) => {
          if (!isLogged) {
            this.router.navigateByUrl('error');
          }
        })
      );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isUserLoggedIn()
      .pipe(
        tap((isLogged: boolean) => {
          if (!isLogged) {
            this.router.navigateByUrl('error');
          }
        })
      );
  }
}
