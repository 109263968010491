import {NgModule} from '@angular/core';
import {AuthGuard} from './security/guards/auth-guard.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [HttpClientModule],
  providers: [AuthGuard]
})
export class CoreModule {
}
