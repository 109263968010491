export interface ProgressButtonOptions {
  active: boolean;
  text: string;
  spinnerSize: number;
  raised: boolean;
  stroked: boolean;
  buttonColor: string;
  spinnerColor: string;
  fullWidth: boolean;
  disabled: boolean;
  mode: string;
}

export interface ButtonHoldOptions {
  active: boolean;
  progressActive: boolean;
  spinnerActive: boolean;
  text: string;
  spinnerSize: number;
  raised: boolean;
  stroked: boolean;
  buttonColor: string;
  spinnerColor: string;
  fullWidth: boolean;
  disabled: boolean;
  mode: string;
}

/**
 * Import default progress-button options
 * usage EXAMPLE:
 *
 *      spinnerButtonOptions: ProgressButtonOptions = {...defaultProgressButtonOpts};
 *
 */
export const defaultProgressButtonOpts = {
  active: false,
  text: 'Hold to open',
  spinnerSize: 19,
  raised: true,
  stroked: false,
  buttonColor: 'primary',
  spinnerColor: 'accent',
  fullWidth: false,
  disabled: false,
  mode: 'indeterminate',
};

export const defaultButtonHoldOptions = {
  active: false,
  progressActive: false,
  spinnerActive: false,
  text: 'Hold to open',
  spinnerSize: 19,
  raised: true,
  stroked: false,
  buttonColor: 'accent',
  spinnerColor: 'accent',
  fullWidth: false,
  disabled: false,
  mode: 'indeterminate',
};
