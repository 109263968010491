import {Component, Input, OnDestroy} from '@angular/core';
import {Door} from '@models/door.model';
import {ButtonHoldOptions, defaultButtonHoldOptions} from '@models/progress-button-options.model';
import {DoorsService} from '@core/security/services/doors.service';
import {finalize} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-door',
  templateUrl: './door.html'
})
export class DoorComponent implements OnDestroy {
  @Input() door: Door;
  @Input() isOpen = false;

  timer$: any;

  spinnerButtonOptionsEnabled: ButtonHoldOptions = {...defaultButtonHoldOptions};
  spinnerButtonOptionsDisabled: ButtonHoldOptions = {...defaultButtonHoldOptions, text: 'No access', disabled: true};

  constructor(private snackBar: MatSnackBar, private doorsService: DoorsService) {
  }

  ngOnDestroy() {
    this.clearRefreshTimer();
  }

  openDoor() {
    this.spinnerButtonOptionsEnabled = {
      ...this.spinnerButtonOptionsEnabled,
      active: true, disabled: true
    };

    this.doorsService.openDoor(this.door.id)
      .pipe(
        finalize(() => {
          this.spinnerButtonOptionsEnabled = {...this.spinnerButtonOptionsEnabled, active: false, spinnerActive: false};
          this.refreshDoorStatus();
        })
      )
      .subscribe(({status}) => {
        if (status === 200 || status === 201) {
          this.isOpen = status === 200 || status === 201;
          this.snackBar.open('Door is now opened!');
        }
      }, () => {
        this.snackBar
          .open('Error! Could not open the door.', 'Retry', {duration: 5000})
          .onAction()
          .subscribe(() => this.openDoor());
      });
  }

  private refreshDoorStatus() {
    this.clearRefreshTimer();

    this.timer$ = setTimeout(() => {
      this.spinnerButtonOptionsEnabled = {...this.spinnerButtonOptionsEnabled, active: false, disabled: false};
      this.isOpen = false;
    }, 5000);
  }

  private clearRefreshTimer() {
    if (this.timer$) {
      clearTimeout(this.timer$);
    }
  }
}
