<div id="page">
  <div id="header">
    <logout-button></logout-button>

    <img *ngIf="logoUrl" src="{{logoUrl}}"
         style="max-width: 200px;max-height: 100px;top: 50%;-ms-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);position: absolute;"
         alt="">
  </div>

  <div class="container">
    <div id="content">
      <mat-card>
        <mat-card-header style="flex-direction: column; align-items: center">
          <mat-card-title>Error invalid link.</mat-card-title>
        </mat-card-header>
        <mat-card-content style="text-align: center">
          <button mat-raised-button color="accent" (click)="retry()">Retry</button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
