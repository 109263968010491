import {Component} from '@angular/core';
import {Door} from '@models/door.model';
import {DoorsService} from '@core/security/services/doors.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-doors-list',
  templateUrl: './doors-list.html'
})
export class DoorsListComponent {
  doors$: Observable<Door[]>;
  displayedColumns = ['door'];

  isBookingActive = false;
  booking;
  location;

  constructor(private doorsService: DoorsService, private router: Router) {
    this.isBookingActive = localStorage.getItem('is_active') === '1';
    const bookingJson = localStorage.getItem('booking');
    const locationJson = localStorage.getItem('location');

    const booking = JSON.parse(bookingJson);

    booking.start_at = moment(booking.start_at).toDate();
    booking.end_at = moment(booking.end_at).toDate();

    this.booking = booking;

    this.location = JSON.parse(locationJson);

    this.doors$ = this.doorsService.getDoors().pipe(
      map((i: any) => i.data as Door[]),
      catchError(e => {
        localStorage.removeItem('authToken');
        router.navigateByUrl('/error').then();

        return throwError(e);
      })
    );
  }

  retry() {
    window.location.reload();
  }
}
