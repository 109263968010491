<!--<mat-card style="margin-top: 4px;">-->
  <mat-list-item>
    <h4 mat-line>{{door.title}}</h4>

    <span mat-line>
      <mat-card-subtitle style="margin-bottom: inherit">{{door.location.title}}</mat-card-subtitle>
    </span>

    <ng-container *ngIf="!isOpen; else doorIsOpenTpl">
      <button-hold [options]="door.is_accessible ? spinnerButtonOptionsEnabled : spinnerButtonOptionsDisabled" (clickHeld)="openDoor()"></button-hold>
    </ng-container>

    <ng-template #doorIsOpenTpl>
      <button mat-button [disabled]="true" [ngStyle]="{'background': 'rgba(0,0,0,.12)', 'color': '#ffffff'}">
        <span class="button-text">Opened</span>
      </button>
    </ng-template>
  </mat-list-item>
<!--</mat-card>-->
