import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'button-hold',
  templateUrl: './hold-button.html',
  styleUrls: ['./hold-button.scss']
})
export class HoldButtonComponent {
  private _options = defaultOptions;
  progressBarValue = 0;

  hideProgressBarTimer$: any;
  btnHeldTimer$: any;

  dropBtnTimer = new Subject();

  @Output() clickHeld: EventEmitter<any> = new EventEmitter();

  @Input() set options(opts: any) {
    let params = opts || {};

    // hide progress-bar if spinner activated
    if (opts && opts.spinnerActive) {
      params = {...params, progressActive: false};
    }

    this._options = {...this._options, ...params};
  }

  get options(): any {
    return this._options;
  }

  handleMouseDown($event) {
    $event.preventDefault();
    if (this.options.disabled) {
      return false;
    }

    this.progressBarValue = 100;
    this.options = {progressActive: true};

    if (this.hideProgressBarTimer$) {
      clearTimeout(this.hideProgressBarTimer$);
    }

    this.startBtnHeldTimer();

    return false;
  }

  handleMouseUp() {
    this.progressBarValue = 0;

    this.endBtnHeldTimer();

    this.hideProgressBarTimer$ = setTimeout(() => {
      this.options = {progressActive: false};
    }, 1000);

    return false;
  }

  triggerBtnHeld() {
    this.dropBtnTimer.next(null);

    if (this.hideProgressBarTimer$) {
      clearTimeout(this.hideProgressBarTimer$);
    }

    this.options = {progressActive: false, spinnerActive: true, disabled: true, active: true};
    this.progressBarValue = 0;

    this.clickHeld.next(null);
  }

  private startBtnHeldTimer() {
    if (this.btnHeldTimer$) {
      clearTimeout(this.btnHeldTimer$);
    }

    this.btnHeldTimer$ = setTimeout(() => this.triggerBtnHeld(), 1000);
  }

  private endBtnHeldTimer() {
    if (this.btnHeldTimer$) {
      clearTimeout(this.btnHeldTimer$);
    }
  }

  handleContextMenuEvent(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }
}

const defaultOptions = {
  active: false,
  progressActive: false,
  spinnerActive: false,
  text: 'Stroked Button',
  buttonColor: 'accent',
  barColor: 'warn',
  raised: false,
  stroked: true,
  mode: 'determinate',
  value: 20,
  disabled: false
};
